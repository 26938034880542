import { combineReducers } from 'redux'
import {merge} from 'lodash'
import { IStore } from '@Interfaces'

import { BlogsReducer, INITIAL_STATE as blogsState } from '@Redux/blogs/reducer'
import { DictionaryReducer, INITIAL_STATE as dictionaryState } from '@Redux/dictionary/reducer'
import { GeneralReducer, INITIAL_STATE as generalState } from '@Redux/general/reducer'
import { NewsReducer, INITIAL_STATE as newsState } from '@Redux/news/reducer'
import { RecipesReducer, INITIAL_STATE as recipesState } from '@Redux/recipes/reducer'
import { SearchBoxReducer, INITIAL_STATE as searchBoxState } from '@Redux/search-box/reducer'

export const rootState: IStore | any = merge({
  blogs: blogsState,
  dictionary: dictionaryState,
  general: generalState,
  news: newsState,
  recipes: recipesState,
  searchBox: searchBoxState,
})

export default combineReducers({
  blogs: BlogsReducer,
  dictionary: DictionaryReducer,
  general: GeneralReducer,
  news: NewsReducer,
  recipes: RecipesReducer,
  searchBox: SearchBoxReducer,
} as any)
