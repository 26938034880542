import App, { AppContext } from 'next/app'
import getConfig from 'next/config'
import * as React from 'react'
import { Provider } from 'react-redux'
import withRedux from 'next-redux-wrapper'
import { JssProvider } from 'react-jss'
import { MuiThemeProvider } from '@material-ui/core'
import { ThemeProvider } from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider } from '@material-ui/core/styles'
import { createGlobalStyle } from 'styled-components'
import Router from 'next/router'

import { BroodService } from '@Services'
import store from '@Redux/store'
import { IApp, BroodModel } from '@Interfaces'
import theme from '@Themes'

import { GeneralActions } from '@Redux/actions'
import { GTMPageView } from '@Lib/helpers'

const {
  publicRuntimeConfig: { IS_PROFESSIONAL, API_SITE },
} = getConfig()

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Melbourne';
    src: url('/fonts/Melbourne-Light.eot');
    src: url('/fonts/Melbourne-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Melbourne-Light.woff') format('woff'),
    url('/fonts/Melbourne-Light.ttf') format('truetype');
    font-display: auto;
    font-weight: lighter;
    display: swap;
  }

  @font-face {
    font-family: 'Melbourne';
    src: url('/fonts/Melbourne-Bold.eot');
    src: url('/fonts/Melbourne-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Melbourne-Bold.woff') format('woff'),
    url('/fonts/Melbourne-Bold.ttf') format('truetype');
    font-weight: bold;
    font-display: auto;
    display: swap;
  }
    
  @font-face {
    font-family: 'Melbourne';
    src: url('/fonts/Melbourne-Regular.eot');
    src: url('/fonts/Melbourne-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Melbourne-Regular.woff') format('woff'),
    url('/fonts/Melbourne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-display: auto;
    display: swap;
  }

  @font-face {
    font-family: 'Playfair Display';
    src: url('/fonts/PlayfairDisplay-Black.ttf') format('truetype');
    font-weight: normal;
    font-display: auto;
    display: swap;
  }
  
  @font-face {
    font-family: 'atkinson';
    src: url('/fonts/atkinson-hyperlegible-regular.ttf') format('truetype');
    font-weight: normal;
    font-display: auto;
    display: swap;
  }

  @font-face {
    font-family: 'alegreya';
    src: url('/fonts/alegreya-sans-900.ttf') format('truetype');
    font-weight: normal;
    font-display: auto;
    display: swap;
  }

  @font-face {
    font-family: 'dokdo';
    src: url('/fonts/Dokdo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-display: auto;
    display: swap;
  }
   @font-face {
    font-family: 'kanit';
    src: url('/fonts/Kanit-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-display: auto;
    display: swap;
  }

	body {
    -webkit-font-smoothing: auto;	
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 5px;
  }
  
  p {
    margin: 0 0 14px;
  }

  .react-autosuggest__suggestions-list {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.50);
    border: 1px solid #aaaaaa;
    list-style: none outside;
    margin: 0;
    padding-left: 0;
  }
  .react-autosuggest__suggestion {
    > div {
      padding: 5px;
      &, &:hover {
       background:transparent !important;
      }
    }
  }
  .react-autosuggest__suggestion--highlighted {
    background: #eeaf50;
    > div:hover {
      background:transparent !important;
    }
  }

  .searchTermHighlight {
    color: #B70040;
  }

  @media screen and (max-width: 767px) {
    #__ba_panel {
      bottom: 10px; 
      top: auto !important;
    }
  }

  .tip {
    width: 100%;
    display: block;
    color: #1D83AD;
    background-color: #b1e3f8;
    background-image: url('/images/background-${API_SITE === 'FR' ? 'suggestion' : 'tip'}.png');
    background-repeat: no-repeat;
    background-position: center left 10px;
    padding: 1em;
    padding-left: ${API_SITE === 'FR' ? 130 : 60}px;
    font-style: italic;
  }

  .highlight {
    width: 100%;
    display: block;
    color: #cc7000;
    background-color: rgb(177, 227, 248);
    padding: 1em;
    font-style: italic;
  }
`

interface ExtendedAppContext extends AppContext {
  ctx: any
}

class MyApp extends App<IApp.IProps> {
  static async getInitialProps(props: ExtendedAppContext) {
    let pageProps = {}
    const { store } = props.ctx
    const state = store.getState()

    //const ctxCookies: any = cookies(props.ctx)
    // Set the cookie stored lang preference on the HTTP service
    // Http.SetLanguage(ctxCookies['next-i18next'])

    // Fetch general data if needed
    if (!state.general.objectKind) {
      const professional = IS_PROFESSIONAL === 'true'

      const response: BroodModel.GetGeneralResponse = await BroodService.GetGeneral(professional)

      if (response.success) {
        store.dispatch(GeneralActions.Set(response.data))
      }
    }

    if (props.Component.getInitialProps) {
      pageProps = await props.Component.getInitialProps(props.ctx)
    }
    return { pageProps }
  }

  componentDidMount() {
    const jssStyles: any = document.querySelector('#jss-server-side')

    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    Router.beforePopState(({ as }) => {
      if (as.startsWith('/recepten') || as.startsWith('/nieuws')) {
        location.href = as
        return false
      }

      return true
    })

    Router.events.on('routeChangeComplete', (url: string) => GTMPageView(url))
  }

  render(): JSX.Element {
    const { Component, pageProps, store } = this.props

    return (
      <Provider store={store as any}>
        <StylesProvider injectFirst>
          <JssProvider>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <>
                  <CssBaseline />
                  <GlobalStyle />
                  <Component {...pageProps} />
                </>
              </ThemeProvider>
            </MuiThemeProvider>
          </JssProvider>
        </StylesProvider>
      </Provider>
    )
  }
}

export default withRedux(store as any)(MyApp)
