import { ActionConsts } from '@Definitions'
import { IAction, ISearchBox } from '@Interfaces'

type IActionPayload = ISearchBox.Actions.IDataPayload | string

/**
 * INITIAL_STATE
 */
export const INITIAL_STATE: ISearchBox.IInitialState = {
  success: false,
  error: undefined,
  data: {
    searchResults: [],
  },
}

/**
 * REDUCER
 */
export const SearchBoxReducer = (
  state: ISearchBox.IInitialState = INITIAL_STATE,
  action: IAction<IActionPayload>,
) => {
  switch (action.type) {
    case ActionConsts.SearchBox.SetData:
      return {
        ...state,
        data: action.payload,
      }

    case ActionConsts.SearchBox.ResetReducer:
      return INITIAL_STATE

    default:
      return state
  }
}

export default SearchBoxReducer
